import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Fade from 'react-reveal/Fade';
import Text from 'reusecore/src/elements/Text';
import ImageGatsby from 'reusecore/src/elements/ImageGatsby';
import Button from 'reusecore/src/elements/Button';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import BannerWrapper, { BannerContent, BannerImage, ButtonGroup } from './banner.style';

const Banner = () => {
    const data = useStaticQuery(graphql`
        query {
            imageOne: file(relativePath: { eq: "image/appClassic/SkyCrapper2.png" }) {
                publicURL
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <BannerWrapper id='home'>
            <Container>
                <BannerContent>
                    <Fade up>
                        <Heading as='h1' content='A Revolução em Gestão de Produtos Securitizados' />
                    </Fade>
                    <Fade up delay={100}>
                        <Text
                            content='A plataforma Akrual é projetada para atender aos mais diversos e
              complexos Produtos Securitizados. Reduzindo custos e agregando auditoria
              a todas as Operações de forma individualizada, com centros de custos segregados.
              O Software é inteiro em ambiente Web rodando em Cloud. Garantindo maior facilidade de manutenção e acesso.'
                        />
                    </Fade>
                    <Fade up delay={200}>
                        <ButtonGroup>
                            <AnchorLink href='#contactFormSection' offset={84}>
                                <Button className='primary' title='Entre em Contato' />
                            </AnchorLink>
                        </ButtonGroup>
                    </Fade>
                </BannerContent>
                <BannerImage>
                    <Fade up delay={100}>
                        <ImageGatsby
                            fluid={data.imageOne.childImageSharp.fluid}
                            alt={'Banner'}
                            src={data.imageOne.publicURL}
                            useLazy={true}
                        />
                        {/*<ImageGatsby filename='SkyCrapper2.png' alt='Banner' />*/}
                    </Fade>
                </BannerImage>
            </Container>
        </BannerWrapper>
    );
};

export default Banner;
